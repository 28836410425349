.jumbotron-container {
  text-align: center;
  margin: auto;
}

.caption {
  font-weight: bold;
  font-size: 1.15em;
}

#projectCarousel {
  width: 60%;
  margin: auto;
  margin-top: 2em;
  max-width: 1100px;
}