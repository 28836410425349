.accordion {
  width: 80%;
  margin: auto;
  padding-bottom: 1em;
  --bs-accordion-btn-focus-border-color: #797979;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #363636;
  --bs-accordion-active-bg: #dbdbdb;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");

}

.accordion-button {
  font-weight: bold;
}

.accordion-button:not(.collapsed) {
  --bs-bg-opacity: 1;
  color: black;
  background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;;
}