.projectCard {
  text-align: center;
  margin: auto;
  margin: 1em auto 1em auto;
  padding-bottom: 1em;
  width: 80%;
}

.projectInfo {
  margin-top: 1em;
  font-size: 1.1em;
  text-align: left;
}

.clickableCard {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5em;
}

.projectHeaderImage {
  max-width: 55%;
  border-radius: 0%;
}

.projectImage {
  display: inline-block;
  width: 42%;
  margin: 1em;
}

.projectLink {
  display: block;
  text-align: center;
  margin: auto;

}